<template>
  <div>
    <!-- 搜索 -->
    <search-contracting-parties @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('contracting_parties/new')"
        >
          新增
        </a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="effective" slot-scope="effective, record">
        <a-space>
          <a-switch
            :checked="effective"
            :loading="editingEffectiveId === record.id"
            @change="(checked) => changeEffective(checked, record)"
          />
        </a-space>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>
    </a-table>

    <!-- 分页 -->
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 新增签约主体 -->
    <new-contracting-party
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />
    <!-- 编辑签约主体 -->
    <edit-contracting-party
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      @completed="fetchData"
      :id="editingId"
    />
  </div>
</template>

<script>
import SearchContractingParties from '@/views/contracting_parties/Search'
import { hasPermission } from '@/utils/permission'
import { findContractingParties, updateContractingPartyEffective } from '@/api/contracting_party'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      data: [],
      columns: [
        {
          title: '主体名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '关联运营商账户数',
          dataIndex: 'carrier_count',
          width: 200
        },
        {
          title: '有效性',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' },
          width: 200
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: 200,
          fixed: 'right'
        }
      ],
      isShowNewModal: false,
      isShowEditModal: false,
      editingEffectiveId: 0,
      loading: false,
      editingId: 0,
      pagination: {
        total_count: 0
      },
      query: {
      },
      is_effective: true
    }
  },
  components: {
    SearchContractingParties,
    Pagination,
    NewContractingParty: () => import('@/views/contracting_parties/New'),
    EditContractingParty: () => import('@/views/contracting_parties/Edit')
  },
  computed: {
    isHasEditPermission() {
      return hasPermission('contracting_parties/edit')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked
      this.$confirm({
        title: effective ? '确定生效吗?' : '确定失效吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateContractingPartyEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }

      })
    },

    fetchData() {
      this.loading = true
      findContractingParties(Object.assign({}, this.query)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

